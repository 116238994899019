import { ref } from "vue";
const notifications: any = ref([]);
type Notification = {
    id?: string;
    message?: string;
    type?: string;
    header?: string;
};
const addNotification = (notification: Notification = {}) => {
    const data: Notification = {
        id: notification.id || Math.random().toString(36).substr(2, 9),
        message: notification.message,
        type: notification.type || "success",
    };
    if (notification.header) {
        data.header = notification.header;
    }
    notifications.value.push(notification);
};
const removeNotification = (id: string) => {
    const index = notifications.value.findIndex(
        (notification: any) => notification.id === id,
    );
    if (index !== -1) {
        notifications.value.splice(index, 1);
    }
};
export function useNotifications() {
    return {
        notifications,
        addNotification,
        removeNotification,
    };
}
